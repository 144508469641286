import React from "react";
import { Redirect, Route } from "react-router-dom";
import * as FeatherIcon from "react-feather";
import { store } from "../redux/store";

import { isUserAuthenticated, getLoggedInUser } from "../helpers/authUtils";

// auth
const Login = React.lazy(() => import("../pages/auth/Login"));
const Logout = React.lazy(() => import("../pages/auth/Logout"));
const Register = React.lazy(() => import("../pages/auth/Register"));
const ForgetPassword = React.lazy(() => import("../pages/auth/ForgetPassword"));

// dashboard
const Dashboard = React.lazy(() => import("../pages/dashboard"));

// apply
const ApplyLoan = React.lazy(() => import("../pages/apply/ApplyLoan"));
const PreRegistrationDetail = React.lazy(() =>
    import("../pages/apply/components/PreRegistrationDetail")
);

// payment
const Payment = React.lazy(() => import("../pages/payment"));

// 404
const NotFound = React.lazy(() => import("../pages/other/Error404"));

// handle auth and authorization
const PrivateRoute = ({ component: Component, roles, ...rest }) => {
    const { user } = store.getState().Auth;
    return (
        <Route
            {...rest}
            render={(props) => {
                if (user === null) {
                    // not logged in so redirect to login page with the return url
                    return (
                        <Redirect
                            to={{ pathname: "/account/login", state: { from: props.location } }}
                        />
                    );
                }

                // const loggedInUser = getLoggedInUser();
                // // check if route is restricted by role
                // if (roles && roles.indexOf(loggedInUser.role) === -1) {
                //     // role not authorised so redirect to home page
                //     return <Redirect to={{ pathname: '/' }} />;
                // }

                // authorised so return component
                return <Component {...props} />;
            }}
        />
    );
};

// root routes
const rootRoute = {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard" />,
    route: PrivateRoute,
};

// not found route
const notFoundRoute = {
    path: "*",
    exact: true,
    component: NotFound,
    route: Route,
};

// dashboards
const dashboardRoutes = {
    path: "/dashboard",
    loan_status: ["*"],
    name: "Beranda",
    icon: FeatherIcon.Home,
    exact: true,
    component: Dashboard,
    roles: ["Admin"],
    route: PrivateRoute,
};

// apply
const applyRoutes = {
    path: "/apply-loan",
    loan_status: [1],
    hidden: true,
    exact: true,
    component: ApplyLoan,
    roles: ["Admin"],
    route: PrivateRoute,
};

// pre-regist
const preRegistRoutes = {
    path: "/pre-registration",
    loan_status: [5, 6],
    hidden: true,
    exact: true,
    component: PreRegistrationDetail,
    roles: ["Admin"],
    route: PrivateRoute,
};

// payment
const paymentRoutes = {
    path: "/payment",
    loan_status: [5, 6],
    name: "Pembayaran",
    icon: FeatherIcon.Pocket,
    exact: true,
    component: Payment,
    roles: ["Admin"],
    route: PrivateRoute,
};

// auth
const authRoutes = {
    path: "/account",
    name: "Auth",
    children: [
        {
            path: "/account/login",
            name: "Login",
            component: Login,
            exact: true,
            route: Route,
        },
        {
            path: "/account/logout",
            name: "Logout",
            component: Logout,
            exact: true,
            route: Route,
        },
        {
            path: "/account/register",
            name: "Register",
            component: Register,
            exact: true,
            route: Route,
        },
        {
            path: "/account/forget-password",
            name: "Forget Password",
            component: ForgetPassword,
            exact: true,
            route: Route,
        },
    ],
};

// flatten the list of all nested routes
const flattenRoutes = (routes) => {
    let flatRoutes = [];

    routes = routes || [];
    routes.forEach((item) => {
        flatRoutes.push(item);

        if (typeof item.children !== "undefined") {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

// All routes
const allRoutes = [
    rootRoute,
    dashboardRoutes,
    applyRoutes,
    paymentRoutes,
    authRoutes,
    preRegistRoutes,
    notFoundRoute,
];

const authProtectedRoutes = [dashboardRoutes, applyRoutes, paymentRoutes, preRegistRoutes];
const allFlattenRoutes = flattenRoutes(allRoutes);
export { allRoutes, authProtectedRoutes, allFlattenRoutes };
